import $ from 'jquery';

export default class MainMenu {
  init() {
    $('.top-menu li:has(.sub-menu)').addClass("has_submenu");
    $('.toggle_submenu').click(function() {
      if ($(this).text() == '+')  $(this).text('-');
      else $(this).text('+');
      $(this).siblings('.sub-menu').slideToggle().parent('li').toggleClass('opened');
      $(this).parent('li').siblings('li.opened').removeClass('opened').find('.sub-menu').slideUp();
      if ($(this).parent('li').hasClass('opened')) {
        $(this).parent().parent().addClass('parent_opened');
      } else {
        $(this).parent().parent().removeClass('parent_opened');
      }
    });

    $('#toggle_menu').click(function() {
    	$('#menu_content').slideToggle(300);
      $(this).toggleClass('active').parent().toggleClass('opened');
      $('body').toggleClass('menu-opened');
    });
  }
}
